/*
 Common JS that will be loaded on bexio pages
 */
if (document.getElementsByClassName('theme-bexio').length) {
    // language switcher
    if (document.getElementsByClassName('footer-language').length) {
        import(/* webpackChunkName: "languageSwitcher" */'./modules/language_switcher.js');
    }

    // faq
    if (document.getElementsByClassName('faq-item').length) {
        import(/* webpackChunkName: "faq" */ './../../global/js/modules/faq');
    }

    // Salary calculator
    if(document.getElementsByClassName('salary-calculator').length) {
        import(/* webpackChunkName: "salaryCalculatorMagic" */'./magics/salaryCalculatorMagic');
    }
}
